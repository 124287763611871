.mainContainer{
    display: flex;
    justify-content: center;
    place-items: center;
    background-image: url("../../../public/Login_Page.png");
    width: 100vw;
    height: 100vh;
    background-position: 0% 20%;
    background-repeat: no-repeat;
    background-size: cover;
}
.container{
    width: 900px;
    height: 510px;
    border-radius: 25px;
    background-color: #f4f4ff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 8px 8px 12px #00000040;
}
.loginContainer{
    filter: blur(0px);
    -webkit-filter: blur(0);
    display: flex;
    height: 482px;
    width: 872px;
    z-index: 10;
    border-radius: 25px;
}
.imgContainer{
    height: 100%;
    width: 50%;
}
.imgContainer > img{
    object-fit: cover;
    border-radius: 0px 20px 20px 0px;
    width:100%;
    height:99.9%;
    transform: rotateY(180deg);
    width: 100%;
}
.Mobileview{
    display: none;
}
.formContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4ff;
    width: 59%;
    height: 100%;
    border-radius: 31px;
    position: absolute;
    margin-left: 41%;
}
.loginheading{
    margin: 25px 0px 0px 2px;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: #194d9b;
}
.notification{
    margin-top: 22px;
    color: #FF543E;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
}
.subHeading{
    color: #000000;
    opacity: 70%;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-top: 32px;
}
.form{
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    gap: 30px;
}
.form > input{
    outline: none;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    border: none;
    padding: 11px 25px;
    border-radius: 40px;
    width: 300px;
    background-color: #F4F4FF;
    color: #000000;
    box-shadow: 8px 8px 12px #194d9b26, inset 3px 3px 12px #194d9b26;
}
.form > input::placeholder{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    opacity: 60%;
}
.bottom{
    display: flex;
    flex-direction: column;
    align-self: end;
    margin: 20px 75px 0px 0px;
    gap: 23px;
}
.forgetButton{
    outline: none;
    border: none;
    background-color: #F4F4FF;
    color: #194d9b;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    cursor: pointer;
}
.loginButton{
    height: 40px;
    border-radius: 40px;
    width: 140px;
    border: none;
    background-color: #194d9b;
    color: #F4F4FF;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    box-shadow: 8px 8px 12px #194d9d26;
    transition: box-shadow 0.3s, color 0.2s;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #FF543E;
}

.loginButton:hover{
    box-shadow: inset 140px 0 0 0 #FF543E;
}

@media screen and (max-width: 930px){
    .mainContainer{
        flex-direction: column;
        background-image: url(../../../public/admission_bg.png);
    }
    .container{
        width: 300px;
        height: 421px;
        border-radius: 15px;
        background-color: #ffff;
        box-shadow: 6px 6px 12px #00000040;
    }
    .loginContainer{
        display: block;
        height: 407px;
        width: 301px;
        border-radius: 12px;
    }
    .imgContainer{
        height: 38%;
        width: 94%;
        margin-left: 9px;
    }
    .imgContainer > img{
        border-radius: 12px 12px 0px 0px;
        width:100%;
        height: 100%;
        transform: rotateY(180deg);
    }
    .Mobileview{
        display: block;
    }
    .Webview{
        display: none;
    }
    .formContainer{
        background-color: #ffff;
        width: 100%;
        height: 42%;
        border-radius: 31px;
        position: static;
        margin-left: 0px;
    }
    .subHeading{
        margin-top: 15px;
    }
    .form{
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        gap: 20px;
    }
    .form > input{
        font-size: 15px;
        padding: 10px 20px;
        width: 205px;
        height: 20px;
    }
    .form > input::placeholder{
        font-size: 15px;
    }
    .bottom{
        flex-direction: column;
        width: 100%;
        align-items: flex-end;
        margin-top: 15px 0px 0px 0px;
        gap: 18px;
    }
    .forgetButton{
        background-color: #fffF;
        width: 50%;
        height: 14px;
    }
    .loginButton{
        height: 35px;
        width: 130px;
    }
    .loginheading{
        margin-top: -100px;
    }
    .notification{
        margin: 8px 0px 30px 0px;
        font-size: 14px;
    }
    .bottom{
        align-self: end;
        margin: 20px 10px;
        gap: 18px;
    }
    .forgetButton{
        font-size: 12px;
    }
    .loginButton{
        height: 36px;
        border-radius: 20px;
        width: 100px;
        font-size: 12px;
        margin-right: 12px;
    }
}