.contact__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    margin-top: 9rem;
    gap: 10rem;
}
.contact__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50%;
    gap: 0.8rem;
}
.contact__left > p {
    font-size: 1.1rem;
}
.contact__left > span {
    font-size: 1.2rem;
    color: #194D9B;
    font-weight: 500;
}
.contact__socials {
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
.contact__socials > a >img {
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
}
.contact__right {
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    box-shadow: 6px 6px 12px rgba(22, 69, 140, 0.16);
    border-radius: 1rem;
    padding: 1rem 2rem;
    gap: 0.8rem;
    width: 30%;
}
.contact__right > span {
    font-size: 1.3rem;
    font-weight: 500;
    color: #194D9B;
    text-align: center;
}
.contact__right > input, .contact__right > textarea , .contact__right > select{
    outline: none;
    border: 2px solid rgba(25, 77, 155, 0.8);
    /* height: 1rem; */
    padding: 0.8rem;
    border-radius: 0.7rem;
    font-family: 'poppins';
}
.contact__right > button {
    width: 40%;
    margin: auto;
    font-size: 1rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.iframe__map {
    width: 100%;
    /* margin: auto; */
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #194D9B;
    padding: 3rem;
    gap: 5rem;
}
.iframe__map > span {
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.16);
}

.contact__details{
    color: #ffff;
    display: flex;
    gap: 4rem;
    font-size: 1rem;
}
.contact__details__heading{
    font-size: 1.15rem;
    margin-bottom: 10px;
    font-weight: 600;
}

.contact__details__address{
    display: flex;
    flex-direction: column; 
    gap: 0.8rem;
}

.contact__details__links{
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.contact__details__links > a:hover{
    font-size: 1.02rem;
}

.copyright__div {
    padding: 1rem;
    text-align: center;
    color: white;
    background-color: #00255B;
}

.button__inactive {
    background-color: gray;
    margin-top: 30px;
    height: 50px;
    width: 225px;
    gap: 40px;
    border-radius: 30px;
    color: white;
    font-family: 'Gemunu Libre', sans-serif;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 1s;
    transition: ease-out 1s;
    overflow: hidden;
}

@media screen and (max-width: 800px) {
    .contact__container {
        flex-direction: column;
        margin-top: 2.5rem;
        gap: 3rem;
    }
    .contact__left {
        text-align: center;
        width: 80%;
        gap: 0rem;
    }
    .contact__left > p{
        font-size: 14px;
    }
    .contact__left > span {
        margin-top: 10px;
        font-size: 16px;
    }
    .contact__socials > a >img {
        width: 1.5rem;
        height: 1.5rem;
    }
    .contact__right {
        border-radius: 10px;
        gap: 0.8rem;
        width: 80%;
    }
    .contact__right > span {
        text-align: center;
    }
    .contact__right > input, .contact__right > textarea {
        border: 1px solid rgba(25, 77, 155, 0.8);
        padding: 0.7rem;
        border-radius: 0.5rem;
    }
    .contact__right > button {
        font-size: 0.8rem;
    }
    .iframe__map {
        width: auto;
        padding: 1.2rem;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.4rem;
    }

    .contact__details{
        gap: 2rem;
        font-size: 0.75rem;
    }
    .contact__details__heading{
        font-size: 0.9rem;
        margin-bottom: 5px;
    }

    .iframe__map > span {
        border-radius: 0.5rem;
        margin-left: 0rem;
    }
    .iframe__map > span > iframe{
        width: 300px;
        height: auto;
    }
    .copyright__div {
        font-size: 9px;
        text-align: center;
    }
}