@import url('https://fonts.googleapis.com/css2?family=Tiro+Devanagari+Hindi&display=swap');
.home{
    height: 90vh;
    background-color: #F4F4FF;
    display: flex;
}
.leftContainer{
    width: 60vw;
    height: 100%;
}
.rightContainer{
    width: 30vw;
    height: 100%;
}
.heading{
    font-size: 90px;
    font-family: 'Gemunu Libre', sans-serif;
    line-height: 90px;
    margin: 40px 0px 0px 110px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
}
.Istheading{
  font-size: 40px;
  margin-bottom: -20px;
  font-family: 'Tiro Devanagari Hindi', serif;
  color: #FF543E;
}
.headingSpan{
    font-weight: 550;
    color: #FF543E;
}
.IIIlustration{
    position: absolute;
    height: 431px;
    width: 524px;
    margin: 80px 0px 0px 230px;
}
.IIIlustrationImg{
    height: 500px;
    width: 608px;
}
.headingBottom{
    margin-top: 30px;
    box-shadow: 6px 6px 20px -7px #194D9B;
    height: 50px;
    width: 225px;
    gap: 40px;
    border-radius: 30px;
    background-color: #194D9B;
    color: white;
    font-family: 'Gemunu Libre', sans-serif;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 1s;
    transition: ease-out 1s;
    cursor: pointer;
    overflow: hidden;
    box-shadow: inset 0 0 0 0 #FF543E;
}
.headingBottom:hover{
    box-shadow: inset 400px 0 0 0 #FF543E;
}
.buttonImg{
    height: 25px;
    width: 25px;
    transform: rotate(-90deg);
}
.description{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    font-size: 18px;
}
.descriptionSpan{
    line-height: 131%;
}

.rightContainer {
    padding: 50px;
    height: 100%;
    overflow: hidden;
    margin: auto;
}


/* --------------------------------------------------- */
/* Swiper CSS */
.swiper {
    height: 30rem;
    width: 90%;
    padding: 1rem 0;
  }
  
.swiper-slide {
    width: auto;
    height: 25rem;
  }

.swiper-slide img {
    width: 15rem;
    height: 25rem;
    border-radius: 2rem;
    object-fit: cover;
  }
  
.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: block;
    opacity: 0%;
    filter: blur(10) !important;
  }

.slider-controller {
    display: none !important;
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.slider-controller .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
  }

.slider-controller .swiper-button-prev {
    left: 42% !important;
    transform: translateX(-42%) !important;
  }

  @media screen and (max-width: 1375px){
    .IIIlustration{
      display: none;
    }
  }

  @media screen and (max-width: 800px){
    .home{
      height: 100svh;
      width: 100svw;
      background-color: #F4F4FF;
      display: flex;
      flex-direction: column;
    }
    .leftContainer{
        width: 100%;
        height: 50%;
    }
    .rightContainer{
        width: 100%;
    }
    .heading{
      font-size: 36px;
      line-height: 30px;
      margin: 0px;
      width: 100%;
    }
    .Istheading{
      font-size: 18px;
      margin: 12px 0px 0px 30px;
      margin-bottom: -7px;
    }
    .IIIrdheading{
      margin: 12px 0px 0px 30px;
    }
    .IIIlustration{
      display: block;
      height: 150px;
      width: auto;
      margin: 60px 0px 0px 180px;
    }
    .IIIlustrationImg{
      height: 150px;
      width: auto;
    }
    .headingBottom{
      margin: 12px 0px 0px 30px;
      margin-top: 15px;
      height: 35px;
      width: 136px;
      gap: 20px;
      font-size: 14px;
    }
    .buttonImg{
        height: 14px;
        width: 14px;
    }
    .description{
        margin-top: 15px;  
        margin: 12px 0px 0px 30px;
        font-size: 12px;
    }
    .rightContainer {
        padding: 0px;
        margin: -80px 0px 0px 0px;
        height: 58%;
    }

    .swiper {
        height: 89.5%;
        width: 80%;
        padding-top: 10px;
      }

      .swiper-slide {
        width: 14rem;
        height: 90%;
      }
    
    .swiper-slide img {
        height: 22rem;
        width: auto;
        border-radius: 0.5rem;
        object-fit: cover;
        box-shadow: 6px 6px 12px rgb(25, 77, 155, 0.16);
      }
      
    .swiper-slide-shadow-left,
    .swiper-slide-shadow-right  img{
        display: block;
        opacity: 0%;
      }
    
    .slider-controller {
        display: none;
      }
  }
