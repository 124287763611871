.notifications__main__container{
    display: flex;
    justify-content: center;
    font-family: "Poppins";
}
.notifications__container{
    display: flex;
    width: 80%;
    margin: 2% 0px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 8px 8px 12px rgb(25, 77, 155, 0.16);
}
.notification__select{
    width: 20%;
    margin-top: 1%;
}
.notifications__options{
  margin: 1% 5%;
  border-radius: 10px 0px 0px 10px;
  padding: 5% 5%;
  font-size: 16px;
  font-weight: 500;
  color: #194D9B;
  font-family: 'Poppins';
  cursor: pointer;
}
.notification__map__container{
    width: 80%;
}
@media screen and (max-width: 800px) {
    .notifications__container{
        flex-direction: column;
    }
    .notification__select{
        width: 100%;
        margin-top: 1%;
        display: flex;
    }
    .notification__map__container{
        margin: 0px 0px 0px 2%;
        width: 80%;
    }
}
