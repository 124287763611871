.results__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    font-family: 'Poppins';
}

.results__top__heading{
    height: 20%;
    width: 80%;
    display: flex;
}

.results__inner__container{
    width: 80%;
    margin-top: -10px;
    background-color: #ffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #194d98;
    box-shadow: 6px 6px 12px #194D9B1A;
}

.result__container__table{
    width: 100%;
    text-align: left;
}

.result__name__heading{
    padding: 2% 4%;
}

table{
    width: 100%;
    padding: 0px 2% 2%;
}

td, th {
    border: none;
    padding: 8px;
  }

tr:nth-child(odd) {
    border: none;
    background-color: #F4F4FF;
}

.pdf__icon__th{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.log__out__btn{
    float: right;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    border-radius: 40px;
    width: 90px;
    border: none;
    color: #fff;
    background-color: #194d9b;
    font-size: 12px; 
    font-weight: 600;
    transition: box-shadow 0.3s, color 0.2s;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #FF543E;
    font-family: 'Poppins';
    text-decoration: none;
    text-align: center;
}

.log__out__btn:hover{
    box-shadow: inset 160px 0 0 0 #FF543E;
}