.Adm_container{
    height: 130vh;
    width: 100vw;
    background-image: url("../../../public/admission_bg.png");
    background-attachment: fixed;
    background-position: center;
    overflow-y: auto;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins';
    gap: 20px;
}
.Adm_heading{
    margin-top: 20px;
}
.Adm_heading > span{
    color: #194D9B;
    font-size: 30px;
    font-weight: 600;
}
.Adm_sub_heading{
    margin-top: -15px;
}
.Adm_sub_heading > span{
    color: #FF543E;
    font-size: 24px;
    font-weight: 600;
}
.Adm_form_container{
    width: 700px;
    height: 758px;
    margin-top: -10px;
    background-color: #ffff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 6px 6px 12px #194D9B1A;
}
.Adm_form{
    width: 674px;
    height: 97%;
    border-radius: 10px;
}
.Adm_form_img_container > img{
    width: 674px;
    height: 240.36px;
}
.Adm_form_form{
    padding: 0px 30px;
}
.Adm_form_form_heading{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Adm_form_form_heading > span{
    margin-top: 10px;
    color: #194D9B;
    font-size: 24px;
    font-weight: 600;
}
.Adm_form_form_heading > hr{
    border:1px solid #194D9B;
    width: 300px;
    margin-top: -1px;
}
.Adm_form_form_Ist{
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    gap: 2px 
}
.Adm_form_form_Ist_input{
    display: flex;
    justify-content: space-between;
}
.label{
    font-size: 15px;
}
.Adm_form_form_Ist_input > input{
    width: 183px;
    height: 34px;
    outline: none;
    font-size: 15px;
    padding-left: 10px;
    border: 1.6px solid #194D9BA6;
    border-radius: 7px;
    opacity: 80%;
}
.Adm_form_form_Ist_input > input::placeholder{
    font-size: 14px;
}
.Adm_form_form_IInd{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
.Adm_form_form_IInd_DOB{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.Adm_form_form_IInd_DOB > input{
    width: 173px;
    height: 34px;
    outline: none;
    padding: 0px 10px;
    border: 1.6px solid #194D9BA6;
    border-radius: 7px;
    font-size: 14px;
    text-transform: uppercase;
    opacity: 80%;
    font-family: "Poppins";
}
.Adm_form_form_IInd_DOB > input[type=date]::-webkit-calendar-picker-indicator{
    cursor: pointer;
    background-image: url(../../../public/calender_icon.png);
}
.Adm_form_form_IInd_Age{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.Adm_form_form_IInd_Age > input{
    width: 127px;
    height: 34px;
    outline: none;
    font-size: 14px;
    padding: 0px 10px;
    border: 1.6px solid #194D9BA6;
    border-radius: 7px;
    opacity: 80%;
}
.Adm_form_form_IInd_Course{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.Adm_form_form_IInd_Course > select{
    width: 247px;
    height: 36px;
    outline: none;
    font-size: 15px;
    padding: 0px 10px;
    border: 1.6px solid #194D9BA6;
    border-radius: 7px;
    opacity: 80%;
    cursor: pointer;
}
.Adm_form_form_IInd_Course > select  option{
    color: rgb(0, 0, 0, 0.8);
}
.Adm_form_form_IIIrd{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 2px;
}
.Adm_form_form_IIIrd_email_Id{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.Adm_form_form_IIIrd_email_Id > input{
    width: 278px;
    height: 34px;
    outline: none;
    font-size: 14px;
    padding: 0px 10px;
    border: 1.6px solid #194D9BA6;
    border-radius: 7px;
    opacity: 80%;
}
.Adm_form_form_IIIrd_no{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.Adm_form_form_contactNo{
    display: flex;
    align-items: center;
    gap: 8px;
}
.ind{
    color: #194D9B;
    font-weight: 500;
}
.Adm_form_form_contactNo > img{
    width: 26px;
    height: 18px;
}
.Adm_form_form_contactNo > input{
    width: 173px;
    height: 34px;
    outline: none;
    font-size: 14px;
    padding: 0px 10px;
    border: 1.6px solid #194D9BA6;
    border-radius: 7px;
    opacity: 80%;
}
.Adm_form_form_Vth_name{
    display: flex;
    flex-direction: column;
    gap: 2px;
}
.Adm_form_form_Vth_name > input {
    width: 282px;
    height: 34px;
    outline: none;
    font-size: 14px;
    padding: 0px 10px;
    border: 1.6px solid #194D9BA6;
    border-radius: 7px;
    opacity: 80%;
}
.Adm_form_Bottom{
    display: flex;
    margin: 22px 30px;
    justify-content: flex-end;
    align-content: center;
}
.Adm_form_Bottom > button{
    width: 120px;
    border-radius: 30px;
    border: none;
    outline: none;
    height: 34px;
    background-color: #194D9B;
    color: #ffff;
    font-size: 14px;
    font-weight: 400;
    box-shadow: 8px 8px 12px rgb(25,77,155,0.16);
    transition: box-shadow 0.3s, color 0.2s;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #FF543E;
}

.Adm_form_Bottom > button:hover {
    box-shadow: inset 120px 0 0 0 #FF543E;
}

@media screen and (max-width: 730px) {
    .Adm_container{
        background-image: url(../../../public/Mobile_Admission_bg.png);
    }
    .Adm_heading {
        margin-top: 17px;
    }
    .Adm_heading > span{
        font-size: 24px;
    }
    .Adm_sub_heading > span{
        font-size: 20px;
    }
    .Adm_form_container{
        width: 360px;
        height: 947px;
    }
    .Adm_form{
        width: 341px;
        margin-bottom: 0.5rem;
    }
    .Adm_form_img_container > img{
        width: 100%;
        height: 122px;
    }
    .Adm_form_form{
        padding: 0px 15px;
    }
    .Adm_form_form_heading > span{
        font-size: 16px;
        margin: 0px;
        font-weight: 500;
    }
    .Adm_form_form_heading > hr{
        width: 195px;
        border-top:#194D9B;
    }
    .label{
        font-size: 12px;
    }
    .Adm_form_form_Ist{
        margin: 0px;
    }
    .Adm_form_form_Ist_input{
        display: block;
    }
    .Adm_form_form_Ist_input > input{
        width: 135px;
        height: 32px; 
    }
    .secondinput{
        margin-left: 10px;
    }
    .thirdinput{
        margin-top: 10px;
    }
    .Adm_form_form_IInd{
        display: block;
    }
    .Adm_form_form_IInd_DOB > input{
        width: 92%;
        height: 35px;
        margin-bottom: 10px;
    }
    .Adm_form_form_IInd_Age > input{
        width: 92%;
        height: 35px;
        margin-bottom: 10px;
    }
    .Adm_form_form_IInd_Course > select{
        width: 99.5%;
        height: 35px;
        margin-bottom: 10px;
    }
    .Adm_form_form_IIIrd{
        display: block;
    }
    .Adm_form_form_IIIrd_email_Id{
        margin-top: 0px;
    }
    .Adm_form_form_IIIrd{
        margin: 0px;
    }
    .Adm_form_form_IIIrd_email_Id > input{
        width: 92%;
        margin-bottom: 10px;
    }
    .Adm_form_form_contactNo > input{
        width: 59%;
        margin-bottom: 3px;
    }
    .fcontact{
        margin-top: 10px;
    }
    .Adm_form_form_Vth_name{
        margin-top: 3px;
    }
    .Adm_form_form_Vth_name > input{
        width: 92.2%;
    }
    .Adm_form_Bottom{
        margin-right: 16px;
    }
  }