@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');
.ins__container{
    background-image: linear-gradient(0deg,
    rgba(244, 244, 244, 1),
    rgb(25, 77, 155, 1));
    height: 293vh;
    background-size: cover;
    font-family: 'poppins';
}
.image-Ie_img_container {
    width: 100%; 
    height: 100%;
    overflow: hidden;
}
.ins__bg_kinematics_left {
    transform: rotate(90deg) translateX(46%) translateY(715%); 
    width: 100%; 
    height: auto;
    position: absolute;
}
.ins__bg_kinematics_right{
    transform: rotate(270deg) translateX(-70%) translateY(715%); 
    width: 100%; 
    height: auto;
    position: absolute;
}
.ins__heading_container{
    display: flex;
    justify-items: center;
    align-items: center;
    position: absolute;
    margin: 25px 100px;
    color: white;
}
.ins__heading{
    font-family: "Poppins";
    font-size: 30px;
    font-weight: 600;
}
.ins__heading_container > img{
    margin-left: 50px;
    height: 30px;
    width: 30px;
}
.ins__card__upper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10rem;
    gap: 1rem;
    padding-top: 7rem;
    margin-bottom: 4rem;
}
.ins__card__upper > img {
    object-fit: cover;
    width: 25%;
    height: 13rem;
    border-radius: 1rem;
}
.left__grad {
    -webkit-mask-image: -webkit-gradient(linear, right top, left top, 
    from(rgba(0,0,0,1)), to(rgba(0,0,0,0.25)));
}
.right__grad {
    -webkit-mask-image: -webkit-gradient(linear, left top, right top, 
    from(rgba(0,0,0,1)), to(rgba(0,0,0,0.25)));
}
.ins__card__lower {
    width: 100%;
}

.our__directors {
    color: white;
    margin: 5rem 10rem;
}

.ins__card__container__directors {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F4F4FF;
    width: 70%;
    margin: auto;
    border-radius: 2rem;
    margin-top: 10rem;
    padding: 2rem 0rem;
    border: 7px solid white;
    gap: 1.5rem;
    font-size: 0.9rem;
}

.upper__commas {
    position: relative;
    top: -15.5rem;
    flex: 1;
    color: #FF543E;
    font-size: 10rem;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-shadow: 4px 4px 10px rgba(25,77,155,0.16);
}

.lower__commas {
    flex: 1;
    color: #FF543E;
    font-size: 10rem;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    position: relative;
    bottom: -20rem;
    text-shadow: -4px -4px 10px rgba(25,77,155,0.16);
}

.director__card__responsive{
    display: flex;
    gap: 30px;
}

.director__card {
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    text-align: justify;
}
.director_name{
    font-weight: 600;
    font-size: 1.3rem;
    margin-top: 0.5rem;
    color: #194D9B;
}

.director__card > img {
    border: 4px solid whitesmoke;
    border-radius: 2rem;
    width: 80%;
    margin-top: -9rem;
    box-shadow:
    inset 0 -3em 3em rgba(25, 77, 155, 0.25),
    0 0 0 2px white,
    0.3em 0.3em 1em rgba(25, 77, 155, 0.25);
}

.director__vision{
    font-family: 'Salsa', cursive;
    width: 60%; 
    line-height: 25px;
    color: #194D9B;
    margin: 4rem auto; 
    text-align: center;
    font-size: 1.1rem
}

.ins__prev__results {
    margin-top: 5rem;
}

.prev__results_responsive{
    display: none;
}

@media screen and (max-width: 800px) {
    .ins__container{
        height: 240svh;
        width: 100svw;
    }
    .ins__bg_kinematics_right,
    .ins__bg_kinematics_left{
        display: none;
    }
    .ins__heading_container{
        margin: 8px 22px;
    }
    .ins__heading{
        font-size: 18px;
    }
    .ins__heading_container > img{
        margin-left: 15px;
        overflow: clip;
        height: 18px;
        width: 18px;
    }
    .ins__card__upper {
        margin: 0px;
        width: 100%;
        gap: 10px;
        padding-top: 3rem;
        overflow: auto;
        white-space: nowrap;
        margin-bottom: 0rem;
    }
    .responsiveImg{
        display: none;
    }
    .ins__card__upper > img {
        width: 60%;
        height: 120px;
        border-radius: 10px;
    }
    .our__directors {
        margin: 30px 2rem;
    }

    .ins__card__container__directors {
        width: 85%;
        border-radius: 1rem;
        margin-top: 3.5rem;
        padding: 0rem 0rem;
        border: 5px solid white;
        gap: 1rem;
    }
    .upper__commas {
        float: top;
        color: #F4F4FF;
        text-shadow: none;
        font-size: 80px;
    }
    .lower__commas {
        color: #F4F4FF;
        bottom: 0px;
        text-shadow: none;
        font-size: 80px;
    }
    .director__card__responsive{
        flex-direction: column;
        gap: 50px;
        font-size: 12px;
    }
    .director__card {
        width: 100%;
    }
    .director_name{
        font-size: 16px;
    }
    .director__card > img {
        border: 2px solid whitesmoke;
        border-radius: 10px;
        width: 50%;
        margin-top: -2rem;
    }
    .director__vision{
        width: 80%;
        margin: 2rem auto;
        font-size: 1rem;
    }
    .prev__results{
        display: none;
    }
    .prev__results_responsive{
        display: block;
    }
}