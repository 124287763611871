.results__container {
    width: 100%;
}

.results__container > .swiper {
    width: 80%;
    height: 20rem;
}

.results__container > .swiper > .swiper-slide {
    width: 30rem;
    height: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slide__image {
    height: 12rem !important;
    width: 12rem !important;
    border-radius: 50% !important;
    border: 8px solid white;
    box-shadow: 6px 6px 12px rgba(25, 77, 155, 0.16);
    cursor: grab;
}
.slide__text {
    text-align: center;
    font-weight: 500;
    margin: 0.1rem;
}

@media screen and (max-width: 800px) {
    .results__container > .swiper {
        width: 100%;
        height: 11rem;
    }

    .results__container > .swiper > .swiper-slide {
        width: 20rem;
        height: 11rem;
    }

    .slide__image {
        height: 7rem !important;
        width: 7rem !important;
        border: 3px solid white;
    }

    .slide__text {
        font-size: 10px;
        margin: 0px;
    }
}