.about__container{
    background-color: #F4F4FF;
    width: 80%;
    margin: 2.5rem auto;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.about__container > div{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.about__page__headings{
    font-size: 1.5rem;
    color: #194D9B;
    font-weight: 600;
}
.about__page__description{
    text-align: justify;
}
.about__container > div > div > span{
    color: #FF543E;
    font-size: 1.1rem;
    font-weight: 500;
}
