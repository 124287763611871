.notifications{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 1% 2%;
    color: rgb(0, 0, 0, 0.6);
}
.upload_date{
    font-size: 12px;
    margin-bottom: -0.5%;
}
.notification__heading{
    font-weight: 500;
}
