.popup__container{
    display: flex;
    margin: 0.4rem;
    justify-content: space-between;
    border-radius: 10px;
    background-color: #ffffff;
    color: rgb(0, 0, 0, 0.60);
}
.popup__container > span{
    width: 350px;
    padding: 0.5rem 1rem;
}
.popup__container > svg{
    padding:0rem 0.4rem;
    width: 30px;
    opacity: 40%;
}