.courses__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    justify-content: center;
    margin: auto;
}

.course__img{
    padding: 0px 100px;
}

.courses__container__top {
    display: flex;
    font-size: 1.3rem;
    align-self: center;
    width: 100%;
    gap: 5rem;
}

.courses__container__top .courses__container__right {
    flex: 1;
}

.courses__container__top__left {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.headingBottom{
    margin-top: 30px;
    box-shadow: 6px 6px 20px -7px #194D9B;
    height: 50px;
    width: 220px;
    border-radius: 30px;
    background-color: #194D9B;
    color: white;
    font-family: 'Gemunu Libre', sans-serif;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    transition: box-shadow 0.1s, color 0.1s;
    cursor: pointer;
    overflow: hidden;
    box-shadow: inset 0 0 0 0 #FF543E;
}
.headingBottom:hover{
    color: #ffff;
    box-shadow: inset 120px 0 0 0 #FF543E;
}
.buttonspan{
    padding-left: 35px;
    margin-left: -35px;
}
.buttonImg{
    height: 25px;
    width: 25px;
    transform: rotate(-90deg);
}

.courses__container__bottom {
    display: flex;
    margin-top: 40px;
    overflow: hidden;
}
.course__img__responsive{
    display: none;
}

@media screen and (max-width: 800px) {
    .courses__container__top {
        font-size: 12px;
    }
    .courses__container__top__right{
        display: none;
    }
    .courses__container__top__left {
        text-align: justify;
        justify-content: start;
    }
    .course__img{
        padding: 0;
    }
    .buttonspan{
        padding-left: 30px;
    }
    .headingBottom.registerbtn{
        margin: 0px;
    }
    .courses__container__bottom {
        display: flex;
        margin-top: 25px;
    }
    .courses__container__bottom > img{
        width: auto;
        height: 80px;
    }
    .course__img > img {
        padding: 0px;
        height: 80px;
    }

}