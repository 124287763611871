.navbar__container {
    display: flex;
    flex-direction: column;
    font-family: 'Poppins', sans-serif;
    box-shadow: 0px 6px 12px #194D9B26;
    position: sticky;
}

.nav__top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    background: #194D9B;
}

.nav__bgtext{
    position: absolute;
    font-size: 90px;
    user-select: none;
    color: #ffffff0a;
    margin: -37px 0px 0px -28px;
    font-weight: 900;
    line-height: 130%;
}

.nav__top__logo {
    flex: 1;
    margin-top: 50px ;
    padding-top: 27px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    z-index: 10px;
}

.responsive_nav_text{
    display: none;
}

.nav__top__logo > img {
    width: 115px;
    height: 115px;
    margin-left: -160px;
    filter: drop-shadow(6px 6px 12px #194D9B40);
}

.nav__top__options {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    gap: 60px;
}

.nav__top__options > a {
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 1rem;
    transition: 0.5s;
    z-index: 10;
}
.nav__top__options > a:hover {
    font-size: 1.3rem;
    color: rgb(196, 196, 196);
}

.nav__top__login {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav__top__login > button {
    width: 110px;
    margin-right: -160px;
    height: 36px;
    background-color: white;
    border-radius: 20px;
    border: none;
    font-weight: 550;
    font-size: 1.1rem;
    transition: box-shadow 0.3s, color 0.2s;
    cursor: pointer;
    overflow: hidden;
    box-shadow: inset 0 0 0 0 #FF543E;
    color: #194D9B;
}

.nav__top__login > button:hover {
    color: #ffff;
    box-shadow: inset 120px 0 0 0 #FF543E;
}

.nav__top__menu__bar{
    display: none;
}

.nav__bottom {
    height: 45px;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
}

.nav__botton__text__margin{
    flex: 1;
}

.nav__bottom__text {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FF543E;
    font-size: 1.9rem;
    font-weight: 700;
    letter-spacing: 0.1rem;
}
.nav__bottom__text > span{
    text-align: center;
}

.nav__bottom__socials {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 23px;
    margin-left: -10px;
}

.nav__bottom__socials > a > img {
    width: 25px;
    height: 25px;
    object-fit: contain;
}

@media screen and (max-width: 800px){
    .navbar__container {
        width: 100svw;
    }
    .nav__top {
        width: 100%;
        height: 50px;
    }
    .nav__bgtext{
        display: none;
    }
    .nav__top__logo {
        flex: 1;
        margin-top: 35px;
        padding-top: 0px;
    }
    .responsive_nav_text{
        flex: 2;
        font-size: 18px;
        font-weight: 600;
        color: #ffff;
        display: block;
    }
    .nav__top__logo > img {
        width: 70px;
        height: 70px;
        margin: 0;
        padding: 0;
    }
    .nav__top__options{
        display: none;
    }
    .nav__top__login{
        display: none;
    }
    .nav__top__menu__bar{
        flex: 0;
        display: flex;
        justify-content: flex-end;
        margin: 0px 20px 0px 0px;
    }
    .nav__bottom {
        height: 25px;
    }
    .nav__bottom__text {
        display: none;
    }
    .nav__bottom__socials {
        display: none;
    }
}