.floating__hello {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 8%;
    z-index: 99;
}
/* .floating__hello:hover {
    transition: width 1s;
    width: 9%;
} */
.popup__home{
    position: fixed;
    z-index: 99;
    bottom: 6.7rem;
    right: 1rem;
}

.info{
    position: fixed;
    width: 80px;
    bottom: 4.3rem;
    right: 1rem;
}
.info:hover{
    background-color: red;
}

@media screen and (max-width: 800px) {
    .floating__hello {
        width: 25%;
        right: 0.5rem;
    }
    .floating__hello:hover {
        width: 26%;
    }
}