.youtube__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    margin-top: 8rem;
    gap: 12rem;
    text-align: right;
}

@media screen and (max-width: 800px) {
    .youtube__container{
        width: 77%;
        margin-top: 3.5rem;
        flex-direction: column;
        gap: 20px;
        font-size: 12px;
        text-align: justify;
    }
    .youtube__iframe > iframe{
        width: 300px;
        height: auto;
        margin-bottom: 20px;
    }
}